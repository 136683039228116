/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFile, getProductos, setProducto, updateProducto } from '../../api';
import { StyledPaper } from '../Paper/Paper';
import TablaProductos from '../TablaDeProductos/Tabla';
import { getFechaHora } from '../Utils/validations';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'sku',
    numeric: false,
    disablePadding: true,
    label: 'SKU',
  },
  {
    id: 'nombre',
    numeric: false,
    disablePadding: true,
    label: 'Nombre',
  },

  {
    id: 'claveSat',
    numeric: false,
    disablePadding: false,
    label: 'Clave SAT',
  },
  {
    id: 'acciones',
    numeric: false,
    label: 'Acciones',
  },
];

const detalleColumnas = [
  {
    id: 'peso',
    numeric: false,
    disablePadding: false,
    label: 'Peso',
  },
  {
    id: 'empaque',
    numeric: true,
    disablePadding: false,
    label: 'Empaque',
  },
  {
    id: 'unidad',
    numeric: false,
    label: 'Unidad',
  },
  {
    id: 'caja',
    numeric: false,
    label: 'Caja',
  },
  {
    id: 'contenido',
    numeric: false,
    label: 'Contenido Neto',
  },
  {
    id: 'iva',
    numeric: false,
    label: 'IVA',
  },
  {
    id: 'ieps',
    numeric: false,
    label: 'IEPS',
  },
];

export default function Productos() {
  const {
    inventarios: {
      usuario: { token },
      representantes,
    },
  } = useSelector(state => {
    return state;
  });
  let variant = 'error';
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [listaDeClientes, setListaDeClientes] = useState([]);
  const [editar, setEditar] = useState(false);
  const [editando, setEditando] = useState(false);
  const [productoNombre, setProductoNombre] = useState('');
  const [productoSku, setProductoSku] = useState('');
  const [productoClaveSat, setProductoClaveSat] = useState('');
  const [productoPeso, setProductoPeso] = useState('');
  const [productoEmpaque, setProductoEmpaque] = useState('');
  const [productoUnidad, setProductoUnidad] = useState('');
  const [productoCaja, setProductoCaja] = useState('');
  const [productoContenido, setProductoContenido] = useState('');
  const [productoIva, setProductoIva] = useState('');
  const [productoIeps, setProductoIeps] = useState('');
  const [searched, setSearched] = useState('');
  const [open, setOpen] = useState(false);

  const divStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  };

  const sendSnackA = message => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500 });
  };

  async function getAllProductos() {
    dispatch({ type: 'FETCHING_START' });
    try {
      const { tProductos } = await getProductos(token);
      setFormato(tProductos);
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesión finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    } finally {
      dispatch({ type: 'FETCHING_END' });
    }
  }

  function setFormato(listaProductos) {
    const tmp = [];
    listaProductos.forEach(element => {
      const formateo = {
        id: element.IidArticulo,
        sku: element.SKU,
        nombre: element.Nombre,
        claveSat: element.ClaveSAT,
        acciones: 1,
        extra: element,
        detalles: {
          valores: [
            {
              peso: element.Peso,
              empaque: element.Empaque,
              unidad: element.UnidadMedida,
              caja: element.Unidadporcaja,
              contenido: element.Contenidoneto,
              iva: element.PIva,
              ieps: element.PIEPS,
            },
          ],
        },
      };
      const flag = tmp.find(element2 => element2.IidArticulo === formateo.id);
      if (!flag) {
        tmp.push(formateo);
      }
    });
    setRows(tmp);
    setListaDeClientes(tmp);
  }

  const exportar = async () => {
    const crearXLSX = [];
    let num = 0;
    const { date, time } = getFechaHora();
    const filename = `Productos_${date}-${time}`;
    dispatch({ type: 'FETCHING_START' });
    rows.forEach(element => {
      const {
        claveSat,
        id,
        nombre,
        sku,
        extra: {
          Contenidoneto,
          Empaque,
          PIEPS,
          PIva,
          Peso,
          UnidadMedida,
          Unidadporcaja,
        },
      } = element;
      const row = {
        num: num + 1,
        id,
        sku,
        nombre,
        claveSat,
        ContenidoNeto: Contenidoneto,
        Empaque,
        '%IEPS': PIEPS,
        '%IVA': PIva,
        'Peso Kg': Peso,
        UnidadMedida,
        UnidadPorCaja: Unidadporcaja,
      };
      num++;
      crearXLSX.push(row);
    });
    getFile(crearXLSX, filename);
    dispatch({ type: 'FETCHING_END' });
  };

  useEffect(() => {
    getAllProductos();
  }, []);

  async function editItem(id) {
    const productoEdit = rows.filter(item => item.id === id)[0];
    setEditando(productoEdit);
    setEditar(true);
    setOpen(true);
  }

  useEffect(() => {
    if (editando) {
      setProductoNombre(editando.nombre || '');
      setProductoSku(editando.sku || '');
      setProductoClaveSat(editando.claveSat || '');
      setProductoPeso(editando.detalles.valores[0].peso || '');
      setProductoEmpaque(editando.detalles.valores[0].empaque || '');
      setProductoUnidad(editando.detalles.valores[0].unidad || '');
      setProductoCaja(editando.detalles.valores[0].caja || '');
      setProductoContenido(editando.detalles.valores[0].contenido || '');
      setProductoIva(editando.detalles.valores[0].iva || '');
      setProductoIeps(editando.detalles.valores[0].ieps || '');
    }
  }, [editando]);

  const guardar = async nuevo => {
    const payload = {
      productoContenido,
      productoIva,
      productoNombre,
      productoUnidad,
      productoCaja,
      productoSku,
      productoPeso,
      productoClaveSat,
      productoEmpaque,
      productoIeps,
      editando,
    };
    dispatch({ type: 'FETCHING_START' });
    try {
      const { tProductos } = nuevo
        ? await setProducto(token, payload)
        : await updateProducto(token, payload);
      if (tProductos) {
        variant = 'success';
        sendSnackA('Se guardó el producto con éxito');
        setFormato(tProductos);
        dispatch({ type: 'FETCHING_END' });
        limpiar();
      }
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesión finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    }
  };

  const validarDatos = () => {
    if (
      productoNombre !== '' &&
      productoSku !== '' &&
      productoClaveSat !== '' &&
      productoEmpaque !== '' &&
      productoUnidad !== ''
    ) {
      return false;
    } else {
      return true;
    }
  };

  function limpiar() {
    setEditando(null);
    setEditar(false);
    setProductoNombre('');
    setProductoSku('');
    setProductoClaveSat('');
    setProductoPeso('');
    setProductoEmpaque('');
    setProductoUnidad('');
    setProductoCaja('');
    setProductoContenido('');
    setProductoIva('');
    setProductoIeps('');
    setOpen(false);
  }

  const cancelSearch = () => {
    if (searched !== '') {
      setSearched('');
      setRows(listaDeClientes);
    }
  };

  const handleChange = event => {
    const filteredRows = listaDeClientes.filter(row => {
      return (
        row?.nombre?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.sku?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.claveSat?.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    setRows(filteredRows);
    setSearched(event.target.value);
  };

  const handleClose = () => {
    limpiar();
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={divStyle}>
          <Paper
            component='form'
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: 500,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder='Búsqueda por nombre, sku y clave SAT'
              inputProps={{
                'aria-label': 'Búsqueda por nombre, sku y clave SAT',
              }}
              value={searched}
              onChange={handleChange}
            />
            <IconButton
              type='button'
              sx={{ p: '10px' }}
              aria-label='search'
              onClick={() => cancelSearch()}
            >
              {searched !== '' ? (
                <CloseIcon color='error' />
              ) : (
                <SearchIcon color='primary' />
              )}
            </IconButton>
          </Paper>
        </div>
      </div>
      <div style={divStyle}>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab color='primary' size='medium' aria-label='add' sx={{ mr: 1 }}>
            <AddIcon onClick={() => setOpen(true)} />
          </Fab>
        </Box>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab color='warning' size='medium' aria-label='export' sx={{ mr: 1 }}>
            <FileDownloadIcon onClick={() => exportar()} />
          </Fab>
        </Box>
      </div>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <StyledPaper>
          <Grid container wrap='nowrap' spacing={2}>
            <Grid item xs={12}>
              <TablaProductos
                headers={headCells}
                productos={rows}
                detalles={detalleColumnas}
                editItem={editItem}
                mensaje={'Almacenes '}
              />
            </Grid>
          </Grid>
        </StyledPaper>
      </Box>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        onClose={handleClose}
        maxWidth={'xl'}
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {editar ? 'Actualizar ' : 'Agregar '} producto
        </DialogTitle>
        <DialogContent dividers={'paper'}>
          <div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '45%' }}>
                <TextField
                  id='outlined-nombre'
                  label='Nombre'
                  type='Text'
                  required
                  value={productoNombre}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setProductoNombre(value);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '45%' }}>
                <TextField
                  id='outlined-claveCli'
                  label='SKU'
                  type='Text'
                  required
                  value={productoSku}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setProductoSku(value);
                  }}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '45%' }}>
                <TextField
                  id='outlined-correo'
                  label='Clave SAT'
                  required
                  type='Text'
                  value={productoClaveSat}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setProductoClaveSat(value);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '45%' }}>
                <TextField
                  id='outlined-claveCli'
                  label='Peso'
                  required
                  type='Text'
                  value={productoPeso}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setProductoPeso(value);
                  }}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '45%' }}>
                <TextField
                  id='outlined-correo'
                  label='Contenido Neto'
                  required
                  type='Text'
                  value={productoContenido}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setProductoContenido(value);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '45%' }}>
                <TextField
                  id='outlined-claveCli'
                  label='Empaque'
                  required
                  type='Text'
                  value={productoEmpaque}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setProductoEmpaque(value);
                  }}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '45%' }}>
                <TextField
                  id='outlined-correo'
                  label='Unidad'
                  type='Text'
                  value={productoUnidad}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  onChange={({ target: { value } }) => {
                    setProductoUnidad(value);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '45%' }}>
                <TextField
                  id='outlined-claveCli'
                  label='Productos Caja'
                  required
                  type='Text'
                  value={productoCaja}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setProductoCaja(value);
                  }}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '45%' }}>
                <TextField
                  id='outlined-correo'
                  label='IEPS'
                  type='number'
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  required
                  value={productoIeps}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setProductoIeps(value);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '45%' }}>
                <TextField
                  id='outlined-claveCli'
                  label='IVA'
                  required
                  type='number'
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  value={productoIva}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value } }) => {
                    setProductoIva(value);
                  }}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              {editar ? (
                <>
                  <Button
                    variant='contained'
                    color='error'
                    onClick={() => {
                      limpiar();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant='contained'
                    disabled={validarDatos()}
                    onClick={() => guardar(false)}
                  >
                    Actualizar
                  </Button>
                </>
              ) : (
                <Button
                  variant='contained'
                  disabled={validarDatos()}
                  onClick={() => guardar(true)}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
