/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Button, Divider, MenuItem, Select, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClientes,
  getProductos,
  setOV,
  setOVManual,
  setOvPDF,
  ultimoConsecutivo,
} from '../../api';
import Dialogo from '../DialogoAceptarCancelar/dialogo';
import { StyledPaper } from '../Paper/Paper';
import TablaProductos from '../TablaDeProductos/Tabla';
import {
  formatCurrency,
  getFechaHora,
  validarFolio,
} from '../Utils/validations';

const Ov = () => {
  const {
    inventarios: {
      configuraciones: { tCondicionPago, tUsoCFDI },
      inventarios,
      almacenes,
      clientes,
      usuario: { IidTrabajador, token, Zona, Email, admin },
    },
  } = useSelector(state => {
    return state;
  });
  const { enqueueSnackbar } = useSnackbar();
  let variant = 'error';
  const maximaEntrada = 100000;
  const [validProduct, setValidProduct] = useState(false);
  const [mostrarDinero, setMostrarDinero] = useState(true);
  const [productos, setProductos] = useState([]);
  const [productosAlmacen, setProductosAlmacen] = useState([]);
  const [listaClientes, setListaClientes] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [producto, setProducto] = useState(null);
  const [cantidad, setCantidad] = useState(0);
  const [precioProducto, setPrecioProducto] = useState(0);
  const [totalPedido, setTotalPedido] = useState(0);
  const [subtotalPedido, setSubTotalPedido] = useState(0);
  const [totalIvaPedido, setTotalIvaPedido] = useState(0);
  const [totalIepsPedido, setTotalIepsPedido] = useState(0);
  const [numMax, setNumMax] = useState(0);
  const [editar, setEditar] = useState(false);
  const [editando, setEditando] = useState(false);
  const [condicionPago, setCondicionPago] = useState(null);
  const [usoCFDI, setUsoCFDI] = useState(null);
  const [origenes, setOrigenes] = useState([]);
  const [origen, setOrigen] = useState(null);
  const [ordenCompra, setOrdenCompra] = useState('');
  const [consecutivo, setConsecutivo] = useState(0);
  const [comentarios, setComentarios] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const titulo = 'Generar OV';
  const [mensaje, setMensaje] = useState('¿Deseas generar la OV?');
  const dispatch = useDispatch();
  const listaMov = ['Orden', 'Orden Manual'];
  const [movSeleccionado, setMovSeleccionado] = useState('Orden');
  const listaEspecial = ['Orden Manual'];
  const [fechaFinal, setFechaFinal] = useState(null);

  const Monedas = [
    { label: 'MXN', value: 'MXN' },
    { label: 'USD', value: 'USD' },
  ];
  const [moneda, setMoneda] = useState(Monedas[0]);

  const divStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  };

  const divStyleTotal = {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    marginTop: 10,
    marginRight: 25,
  };

  const divStyleTextArea = {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    marginTop: 15,
    marginLeft: 35,
  };

  const headCells = [
    {
      id: 'id',
      numeric: false,
      label: '#',
    },
    {
      id: 'name',
      numeric: false,
      label: 'Producto',
    },
    {
      id: 'codigo',
      numeric: false,
      label: 'Código',
    },
    {
      id: 'cantidad',
      numeric: true,
      label: 'Cantidad',
    },
    {
      id: 'lote',
      numeric: true,
      label: 'Lote',
    },
    {
      id: 'precio',
      numeric: true,
      label: 'Subtotal',
    },
    {
      id: 'acciones',
      numeric: false,
      label: 'Acciones',
    },
    {
      id: 'totalP',
      numeric: true,
      label: 'Total',
    },
  ];

  const detalleColumnas = [
    {
      id: 'iva',
      label: 'IVA',
    },
    {
      id: 'ieps',
      label: 'IEPS',
    },
    {
      id: 'precioU',
      label: 'Precio U.',
    },
    {
      id: 'precioT',
      label: 'Precio U. Neto',
    },
  ];

  const cancelar = async () => {
    getAlmacenesPropios();
    setProductos([]);
    setCantidad(1);
    setPrecioProducto(0);
    setOrdenCompra('');
    setProducto(null);
    setCliente(null);
  };

  const validarDatos = () => {
    return !(
      origen !== null &&
      condicionPago !== null &&
      cliente !== null &&
      moneda !== null &&
      usoCFDI !== null &&
      productos.length > 0 &&
      consecutivo > 0 &&
      (listaEspecial.includes(movSeleccionado) ? fechaFinal !== null : true)
    );
  };

  const guardar = async () => {
    dispatch({ type: 'FETCHING_START' });
    const almacenOrigen = {
      ClaveAlmacen: origen.ClaveAlmacen,
      idAlmacen: origen.IidAlmacen,
      Centro: origen.Centro,
      Folio: origen.Folio,
      Zona: origen.Zona,
    };
    try {
      const {
        consecutivos: { OVcons },
      } = await ultimoConsecutivo(token, { almacenOrigen });
      setConsecutivo(OVcons);
      const tipoMov = 'OV';
      const padAM = validarFolio(OVcons);
      const folioMov = (origen.Folio + padAM).trim();
      const filename = `OV_${folioMov}`;
      const pedido = {
        totalPedido,
        totalIepsPedido,
        totalIvaPedido,
        subtotalPedido,
      };
      const payload = {
        ConsOV: OVcons,
        mostrarDinero,
        condicionPago,
        almacenOrigen,
        ordenCompra,
        productos,
        folioMov,
        usoCFDI,
        tipoMov,
        cliente,
        pedido,
        moneda,
        Zona,
      };
      payload.cliente.ComentariosEnvio = comentarios;
      const crearXLSX = [];
      productos.forEach((element, index) => {
        const row = {
          num: index + 1,
          producto: element.name,
          sku: element.sku,
          cantidad: element.cantidad,
          subtotal: element.detalles.valores[0].precioU,
          iva: element.detalles.valores[0].iva,
          ieps: element.detalles.valores[0].ieps,
          total: element.detalles.valores[0].precioN,
        };
        if (index === 0) {
          const { date, time } = getFechaHora();
          row.zona = Zona;
          row.representante = Email;
          row.origen = almacenOrigen.ClaveAlmacen;
          row.numeroCliente = cliente.ClaveCliente;
          row.nombreCliente = cliente.NombreCliente;
          row.CFDI = usoCFDI.Claveuso;
          row.usoCFDI = usoCFDI.label;
          row.subtotal = subtotalPedido;
          row.totalIeps = totalIepsPedido;
          row.totalIva = totalIvaPedido;
          row.totalPedido = totalPedido;
          row.folioMovimiento = folioMov;
          row.fecha = `${date} ${time}`;
        }
        crearXLSX.push(row);
      });
      try {
        const { tInventario, tOV, tAlmacenes } = await setOV(token, payload);
        if (tInventario) {
          const tmpCons = OVcons + 1;
          setConsecutivo(tmpCons);
          setConsecutivo(tmpCons);
          dispatch({
            type: 'ALMACENES_FETCHING',
            payload: { almacenes: tAlmacenes },
          });
          dispatch({
            type: 'INVENTARIO_FETCHING',
            payload: { inventarios: tInventario },
          });
          tOV.forEach(element => {
            dispatch({
              type: 'ORDEN_VENTA_ADD',
              payload: { ordeneVenta: element },
            });
          });
          getAlmacenesPropios();
          setProductos([]);
          setCantidad(1);
          setPrecioProducto(0);
          setOrdenCompra('');
          setProducto(null);
          setCliente(null);
          variant = 'success';
          sendSnackA('Se realizo el movimiento con éxito');
          await setOvPDF(token, payload, filename);
          // getFile(crearXLSX, filename);
          dispatch({ type: 'FETCHING_END' });
        }
      } catch (error) {
        variant = 'error';
        if (error.toString().includes('TokenExpiredError')) {
          sendSnackA('Sesion finalizada');
          dispatch({ type: 'RESET_ALL_DATA' });
        } else {
          sendSnackA('Ocurrió un error, intenta mas tarde');
          dispatch({ type: 'FETCHING_END' });
        }
      }
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesion finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    }
  };

  const guardarHistorico = async () => {
    let tmpFolio = origen.Folio;
    console.log(tmpFolio);
    if (!tmpFolio) {
      sendSnackA('El almacen origen no tiene folio asignado');
    }
    const almacenOrigen = {
      ClaveAlmacen: origen.ClaveAlmacen,
      idAlmacen: origen.IidAlmacen,
      Centro: origen.Centro,
      origenDetalle: origen,
      Folio: origen.Folio,
      Zona: origen.Zona,
    };
    const tipoMov = 'OV';
    const padAM = validarFolio(consecutivo);
    const folioMov = (tmpFolio + padAM).trim();
    const filename = `OV_${folioMov}`;
    const pedido = {
      totalPedido,
      totalIepsPedido,
      totalIvaPedido,
      subtotalPedido,
    };
    const payload = {
      fechaOV: fechaFinal?.toJSON().split('T')[0],
      ConsOV: consecutivo,
      mostrarDinero,
      condicionPago,
      almacenOrigen,
      ordenCompra,
      productos,
      folioMov,
      usoCFDI,
      tipoMov,
      cliente,
      pedido,
      moneda,
      Zona,
    };
    payload.cliente.ComentariosEnvio = comentarios;
    const crearXLSX = [];
    productos.forEach((element, index) => {
      const row = {
        num: index + 1,
        producto: element.name,
        sku: element.sku,
        cantidad: element.cantidad,
        subtotal: element.detalles.valores[0].precioU,
        iva: element.detalles.valores[0].iva,
        ieps: element.detalles.valores[0].ieps,
        total: element.detalles.valores[0].precioN,
      };
      if (index === 0) {
        const { date, time } = getFechaHora();
        row.zona = Zona;
        row.representante = Email;
        row.origen = almacenOrigen.ClaveAlmacen;
        row.numeroCliente = cliente.ClaveCliente;
        row.nombreCliente = cliente.NombreCliente;
        row.CFDI = usoCFDI.Claveuso;
        row.usoCFDI = usoCFDI.label;
        row.subtotal = subtotalPedido;
        row.totalIeps = totalIepsPedido;
        row.totalIva = totalIvaPedido;
        row.totalPedido = totalPedido;
        row.folioMovimiento = folioMov;
        row.fecha = `${date} ${time}`;
      }
      crearXLSX.push(row);
    });
    dispatch({ type: 'FETCHING_START' });
    try {
      const { tInventario, tOV, tAlmacenes } = await setOVManual(
        token,
        payload
      );
      if (tInventario) {
        const tmpCons = consecutivo + 1;
        setConsecutivo(tmpCons);
        setConsecutivo(tmpCons);
        dispatch({
          type: 'ALMACENES_FETCHING',
          payload: { almacenes: tAlmacenes },
        });
        dispatch({
          type: 'INVENTARIO_FETCHING',
          payload: { inventarios: tInventario },
        });
        tOV.forEach(element => {
          dispatch({
            type: 'ORDEN_VENTA_ADD',
            payload: { ordeneVenta: element },
          });
        });
        getAlmacenesPropios();
        setProductos([]);
        setCantidad(1);
        setPrecioProducto(0);
        setOrdenCompra('');
        setProducto(null);
        setCliente(null);
        variant = 'success';
        sendSnackA('Se realizo el movimiento con éxito');
        //await setOvPDF(token, payload, filename);
        // getFile(crearXLSX, filename);
        dispatch({ type: 'FETCHING_END' });
      }
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesion finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    }
  };

  const handleChangeFechaFinal = newValue => {
    setFechaFinal(newValue);
  };

  function editItem(id) {
    const productoEdit = productos.filter(item => item.id === id)[0];
    const productTmp = productosAlmacen.filter(
      item => item.SKU === productoEdit.sku
    )[0];
    const { CantidadDisponible } = productTmp;
    const { cantidad } = productoEdit;
    setCantidad(cantidad);
    setNumMax(
      listaEspecial.includes(movSeleccionado)
        ? maximaEntrada
        : CantidadDisponible
    );
    setProducto(productTmp);
    setEditando(productoEdit);
    setEditar(true);
  }

  function deleteItem(id) {
    const newProducto = productos.filter(item => item.id !== id);
    setProductos(newProducto);
  }

  const agregarProducto = async () => {
    let lastId = 0;
    if (productos.length > 0) {
      lastId = Math.max(...productos.map(x => parseInt(x.id))) + 1;
    }
    const { base, ieps, iva } = getPrecioImpuestos(
      precioProducto,
      producto.PIEPS,
      producto.PIva
    );
    const newProduct = {
      id: lastId,
      name: producto.Nombre,
      sku: producto.SKU,
      cantidad: cantidad,
      lote: producto.Lote,
      precio: base * cantidad,
      acciones: true,
      total: (base + ieps + iva) * cantidad,
      detalles: {
        producto,
        valores: [
          {
            iva: iva,
            ieps: ieps,
            precioU: base,
            precioN: base + ieps + iva,
          },
        ],
      },
    };
    setProductos(productos.concat(newProduct));
    setProducto(null);
    setCantidad(1);
    setNumMax(1);
  };

  const actualizarProducto = async () => {
    const { id } = editando;
    let tmpProduct = [...productos];
    tmpProduct = tmpProduct.filter(item => item.id !== id);
    const { base, ieps, iva } = getPrecioImpuestos(
      precioProducto,
      producto.PIEPS,
      producto.PIva
    );
    const newProduct = {
      id,
      name: producto.Nombre,
      sku: producto.SKU,
      cantidad: cantidad,
      lote: producto.Lote,
      precio: base * cantidad,
      acciones: true,
      total: (base + ieps + iva) * cantidad,
      detalles: {
        producto,
        valores: [
          {
            iva: iva,
            ieps: ieps,
            precioU: base,
            precioN: base + ieps + iva,
          },
        ],
      },
    };
    tmpProduct.push(newProduct);
    tmpProduct = tmpProduct.sort(function (a, b) {
      return a.id - b.id;
    });
    setProductos(tmpProduct);
    setProducto(null);
    setCantidad(1);
    setNumMax(1);
    setEditando(false);
    setEditar(false);
  };

  const handleChangeCantidad = event => {
    const newValue = event.target.value;
    setCantidad(newValue <= numMax ? newValue : numMax);
  };

  const handleChangePrecio = event => {
    const newValue = event.target.value;
    setPrecioProducto(newValue);
  };

  useEffect(() => {
    let tmpClientes = [];
    async function fetchData() {
      dispatch({ type: 'FETCHING_START' });
      try {
        const { tClientes } = await getClientes(token);
        tmpClientes = tClientes;
        console.log(tmpClientes);
        tmpClientes.sort(function (a, b) {
          if (a.NombreCliente < b.NombreCliente) {
            return -1;
          }
          if (a.NombreCliente > b.NombreCliente) {
            return 1;
          }
          return 0;
        });
        tmpClientes = await tmpClientes.filter(
          cli => cli.NombreCliente !== null
        );
        setListaClientes(tmpClientes);
        setCliente(tmpClientes[0]);
        const tmp = [];
        almacenes.forEach(almacen => {
          const flag = tmp.find(
            element => element.IidAlmacen === almacen.IidAlmacen
          );
          if (!flag && almacen?.Almacen !== 'SAP') {
            tmp.push(almacen);
          }
        });
        tmp.sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
        setConsecutivo(tmp[0].ConsAM);
        setOrigen(tmp[0]);
        setOrigenes(tmp);
      } catch (error) {
        variant = 'error';
        if (error.toString().includes('TokenExpiredError')) {
          sendSnackA('Sesión finalizada');
          dispatch({ type: 'RESET_ALL_DATA' });
        } else {
          sendSnackA('Ocurrió un error, intenta mas tarde');
          dispatch({ type: 'FETCHING_END' });
        }
      } finally {
        dispatch({ type: 'FETCHING_END' });
      }
    }
    if (listaEspecial.includes(movSeleccionado)) {
      fetchData();
    } else {
      getAlmacenesPropios();
    }
  }, [movSeleccionado]);

  useEffect(() => {
    let flag = false;
    if (producto && cantidad > 0 && productos.length <= 17) {
      flag = true;
    }
    setValidProduct(flag);
  }, [producto, cantidad]);

  useEffect(() => {
    const tmpComentarios = cliente?.Comentarios || '';
    setComentarios(tmpComentarios);
  }, [cliente]);

  useEffect(() => {
    let total = 0;
    let subtotal = 0;
    let iva = 0;
    let ieps = 0;
    if (productos.length > 0) {
      productos.forEach(element => {
        total = total + element.total;
        subtotal = subtotal + element.precio;
        iva = iva + element.detalles.valores[0].iva * element.cantidad;
        ieps = ieps + element.detalles.valores[0].ieps * element.cantidad;
      });
    }
    setTotalIepsPedido(ieps);
    setTotalIvaPedido(iva);
    setSubTotalPedido(subtotal);
    setTotalPedido(total);
  }, [productos]);

  const sendSnackA = message => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500 });
  };

  const getPrecioImpuestos = (base, ieps, iva) => {
    const precioIeps = parseFloat(base * ieps).toFixed(2);
    const precioIva = parseFloat(base * iva).toFixed(2);
    return {
      base: Number(base),
      ieps: Number(precioIeps),
      iva: Number(precioIva),
    };
  };

  useEffect(() => {
    getAlmacenesPropios();
    const tmp = clientes.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    setListaClientes(tmp);
    setCondicionPago(tCondicionPago[0]);
    setUsoCFDI(tUsoCFDI[0]);
  }, []);

  useEffect(() => {
    getAlmacenesPropios();
    const tmpAlmacenes = almacenes.filter(
      almacen =>
        almacen.Iidtrabajador === IidTrabajador ||
        almacen.idSustituto === IidTrabajador
    );
    setConsecutivo(tmpAlmacenes[0]?.ConsOV || 0);
  }, [almacenes]);

  function getAlmacenesPropios() {
    const tmpAlmacenes = almacenes.filter(
      almacen =>
        (almacen.Iidtrabajador === IidTrabajador ||
          almacen.idSustituto === IidTrabajador) &&
        almacen?.Almacen !== 'SAP'
    );
    tmpAlmacenes.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    setOrigenes(tmpAlmacenes);
    setOrigen(tmpAlmacenes[0]);
    const lista = inventarios[tmpAlmacenes[0]?.C_Unica] || [];
    setProductosAlmacen(lista);
  }

  const handleChangeDinero = event => {
    const flag = event.target.checked;
    setMostrarDinero(flag);
  };

  useEffect(() => {
    let tmp = [];
    async function fetchData() {
      dispatch({ type: 'FETCHING_START' });
      try {
        const { tProductos } = await getProductos(token);
        tmp = tProductos;
        tmp.sort(function (a, b) {
          if (a.SKU < b.SKU) {
            return -1;
          }
          if (a.SKU > b.SKU) {
            return 1;
          }
          return 0;
        });
        setProductosAlmacen(tmp);
        setProducto(null);
      } catch (error) {
        variant = 'error';
        if (error.toString().includes('TokenExpiredError')) {
          sendSnackA('Sesión finalizada');
          dispatch({ type: 'RESET_ALL_DATA' });
        } else {
          sendSnackA('Ocurrió un error, intenta mas tarde');
          dispatch({ type: 'FETCHING_END' });
        }
      } finally {
        dispatch({ type: 'FETCHING_END' });
      }
    }
    if (origen !== null && origen) {
      const lista = inventarios[origen.C_Unica] || [];
      let tmpConsecutivo = origen.ConsOV;
      if (listaEspecial.includes(movSeleccionado)) {
        fetchData();
      } else {
        lista.forEach(element => {
          const flag = tmp.find(
            element2 =>
              element2.SKU === element.SKU &&
              element2.Lote === element.Lote &&
              element.Iidtrabajador === IidTrabajador
          );
          if (!flag) {
            tmp.push(element);
          }
        });
      }
      tmp.sort(function (a, b) {
        if (a.SKU < b.SKU) {
          return -1;
        }
        if (a.SKU > b.SKU) {
          return 1;
        }
        return 0;
      });
      setProductosAlmacen(tmp);
      setProductos([]);
      if (movSeleccionado !== 'Orden Manual') {
        setConsecutivo(tmpConsecutivo);
      }
    } else {
      setProductosAlmacen([]);
      setProducto(null);
    }
  }, [origen]);

  async function aceptar() {
    setOpenDialog(false);
    if (movSeleccionado !== 'Orden Manual') {
      guardar();
    } else {
      guardarHistorico();
    }
  }
  async function cerrar() {
    setOpenDialog(false);
  }

  const boxStyle = { marginTop: 10, textAlign: 'center' };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {!admin ? (
          <>
            <Typography variant='h5' gutterBottom>
              Orden de Venta
            </Typography>
          </>
        ) : (
          <>
            <div style={{ display: 'flex' }}>
              <Select
                labelId='demo-simple-select-standard-label'
                id='demo-simple-select-standard'
                value={movSeleccionado}
                onChange={(event, newValue) => {
                  const valor = event.target.value;
                  setMovSeleccionado(valor);
                }}
              >
                {listaMov.map(value => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant='h5' gutterBottom style={{ marginTop: 15 }}>
                de Venta
              </Typography>
            </div>
          </>
        )}
        {movSeleccionado === 'Orden Manual' ? (
          <TextField
            id='outlined-number-OV'
            label='# OV'
            value={consecutivo}
            onChange={event => {
              const newValue = event.target.value;
              setConsecutivo(newValue);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { min: 1, max: numMax },
            }}
            style={{ marginTop: 20 }}
          />
        ) : (
          <Typography variant='h6' gutterBottom>
            OV: #{consecutivo}
          </Typography>
        )}
        <FormControlLabel
          control={
            <>
              <Switch checked={mostrarDinero} onChange={handleChangeDinero} />
              <AttachMoneyIcon />
            </>
          }
          label='Mostrar dinero en PDF'
        />
      </div>
      <br />
      <Divider>
        <ArrowDownwardIcon />
        Datos del cliente
        <ArrowDownwardIcon />
      </Divider>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginLeft: '5%',
        }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Box spacing={1} sx={{ minWidth: '70%' }} style={boxStyle}>
            <Autocomplete
              size='medium'
              options={listaClientes}
              id='auto-cliente'
              clearOnEscape
              value={cliente}
              getOptionLabel={option =>
                listaEspecial.includes(movSeleccionado)
                  ? option.NombreCliente
                  : option.label
              }
              onChange={(event, newValue) => {
                setCliente(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label='Cliente'
                  variant='standard'
                  helperText='Selecciona el Cliente'
                />
              )}
            />
          </Box>
          <Box style={boxStyle}>
            <TextField
              id='outlined-ordenCompra'
              label='Orden de compra'
              type='Text'
              value={ordenCompra}
              onChange={event => {
                const newValue = event.target.value;
                setOrdenCompra(newValue);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Box spacing={1} sx={{ minWidth: '28%' }} style={boxStyle}>
            <Autocomplete
              options={tCondicionPago}
              id='auto-pago'
              clearOnEscape
              value={condicionPago}
              onChange={(event, newValue) => {
                setCondicionPago(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Condiciones de pago'
                  variant='standard'
                  helperText='Selecciona condiciones de pago'
                />
              )}
            />
          </Box>
          <Box spacing={1} sx={{ minWidth: '20%' }} style={boxStyle}>
            <Autocomplete
              options={Monedas}
              id='auto-mondeas'
              clearOnEscape
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              value={moneda}
              onChange={(event, newValue) => {
                setMoneda(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Moneda'
                  variant='standard'
                  helperText='Selecciona Moneda'
                />
              )}
            />
          </Box>
          <Box spacing={1} sx={{ minWidth: '35%' }} style={boxStyle}>
            <Autocomplete
              options={tUsoCFDI}
              id='auto-CFDI'
              clearOnEscape
              value={usoCFDI}
              onChange={(event, newValue) => {
                setUsoCFDI(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Uso CFDI'
                  variant='standard'
                  helperText='Selecciona uso de CFDI'
                />
              )}
            />
          </Box>
        </Stack>
        <br />
        <Divider>
          <ArrowDownwardIcon />
          Producto
          <ArrowDownwardIcon />
        </Divider>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Box spacing={1} sx={{ minWidth: '45%' }} style={boxStyle}>
            <Autocomplete
              options={origenes}
              id='auto-origen'
              clearOnEscape
              value={origen}
              onChange={(event, newValue) => {
                setOrigen(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Almacen'
                  required
                  variant='standard'
                  helperText='Selecciona el almacen'
                />
              )}
            />
          </Box>
          <Box spacing={1} sx={{ minWidth: '45%' }} style={boxStyle}>
            <Autocomplete
              size='medium'
              options={productosAlmacen}
              id='auto-producto'
              clearOnEscape
              value={producto}
              onChange={(event, newValue) => {
                try {
                  if (listaEspecial.includes(movSeleccionado)) {
                    setCantidad(1);
                    setNumMax(maximaEntrada);
                    setProducto(newValue);
                  } else {
                    const { CantidadDisponible, SKU } = newValue;
                    let cantidadSeleccionada = 0;
                    const listaProds =
                      productos?.filter(element2 => element2.sku === SKU) || [];
                    listaProds.forEach(element => {
                      cantidadSeleccionada =
                        cantidadSeleccionada + parseInt(element.cantidad);
                    });
                    setNumMax(CantidadDisponible - cantidadSeleccionada);
                    setCantidad(CantidadDisponible > 0 ? 1 : 0);
                  }
                  setProducto(newValue);
                } catch (error) {
                  setCantidad(0);
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label='Producto'
                  variant='standard'
                  helperText='Selecciona el producto'
                />
              )}
            />
          </Box>
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Box
            sx={{
              minWidth: movSeleccionado === 'Orden Manual' ? '28%' : '45%',
            }}
            style={boxStyle}
          >
            <TextField
              id='outlined-number'
              label='Cantidad'
              type='number'
              required
              style={{ marginTop: 5 }}
              value={cantidad}
              onChange={handleChangeCantidad}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 1, max: numMax },
              }}
              helperText={'Cantidad maxima ' + numMax}
            />
          </Box>
          <Box
            sx={{
              minWidth: movSeleccionado === 'Orden Manual' ? '28%' : '45%',
            }}
            style={boxStyle}
          >
            <TextField
              id='outlined-number'
              label='Precio venta'
              type='number'
              required
              style={{ marginTop: 10 }}
              value={precioProducto}
              onChange={handleChangePrecio}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Box>
          {movSeleccionado === 'Orden Manual' ? (
            <Box sx={{ minWidth: '25%' }} style={boxStyle}>
              <div style={{ marginTop: 10 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label='Fecha OV'
                    inputFormat='DD/MM/YYYY'
                    maxDate={new Date()}
                    minDate={dayjs('2023-04-01')}
                    value={fechaFinal}
                    onChange={handleChangeFechaFinal}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </Box>
          ) : null}
        </Stack>

        <div style={divStyle}>
          {editar ? (
            <>
              <Button
                variant='contained'
                color='error'
                onClick={() => {
                  setCantidad(1);
                  setNumMax(1);
                  setProducto(null);
                  setEditando(null);
                  setEditar(false);
                  setPrecioProducto(0);
                }}
              >
                Cancelar
              </Button>
              <Button
                variant='contained'
                disabled={!validProduct}
                onClick={() => actualizarProducto()}
              >
                Actualizar
              </Button>
            </>
          ) : (
            <Button
              variant='contained'
              disabled={!validProduct}
              onClick={() => agregarProducto()}
            >
              Agregar
            </Button>
          )}
        </div>
      </div>
      <div style={divStyle}>
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <StyledPaper>
            <Grid container wrap='nowrap' spacing={2}>
              <Grid item xs={12}>
                <TablaProductos
                  headers={headCells}
                  productos={productos}
                  detalles={detalleColumnas}
                  editItem={editItem}
                  deleteItem={deleteItem}
                  mensaje={'Productos '}
                />
              </Grid>
            </Grid>
          </StyledPaper>
        </Box>
      </div>
      <Grid container spacing={2}>
        <Grid xs={8}>
          <div style={divStyleTextArea}>
            <TextField
              label='Comentarios de entrega'
              multiline
              minRows={4}
              maxRows={7}
              inputProps={{ maxLength: 500 }}
              style={{ width: '100%' }}
              value={comentarios}
              onChange={event => {
                const newValue = event.target.value;
                setComentarios(newValue);
              }}
            />
          </div>
        </Grid>
        <Grid xs={4}>
          <div style={divStyleTotal}>
            <Typography>SubTotal {formatCurrency(subtotalPedido)}</Typography>
            <Typography>IVA {formatCurrency(totalIvaPedido)}</Typography>
            <Typography>IEPS {formatCurrency(totalIepsPedido)}</Typography>
            <Typography>Total {formatCurrency(totalPedido)}</Typography>
            <Typography>{moneda?.label}</Typography>
          </div>
        </Grid>
      </Grid>
      <br />
      <br />
      <div style={divStyle}>
        <Button variant='contained' color='error' onClick={() => cancelar()}>
          Cancelar
        </Button>
        <Button
          variant='contained'
          disabled={validarDatos()}
          onClick={() => setOpenDialog(true)}
        >
          Guardar
        </Button>
      </div>
      <br />
      <br />
      <Dialogo
        abrir={openDialog}
        cerrar={cerrar}
        titulo={titulo}
        mensaje={mensaje}
        aceptar={aceptar}
      />
    </>
  );
};

export default Ov;
