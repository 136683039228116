const types = {
  USUARIO_LOGIN: 'USUARIO_LOGIN',
  SAVE_TOKEN: 'SAVE_TOKEN',
  FETCHING_START: 'FETCHING_START',
  RESET_DATA: 'RESET_DATA',
  TOKEN_FETCHING: 'TOKEN_FETCHING',
  INVENTARIO_FETCHING: 'INVENTARIO_FETCHING',
  REPRESENTANTES_FETCHING: 'REPRESENTANTES_FETCHING',
  REPRESENTANTE_FETCHING: 'REPRESENTANTE_FETCHING',
  CLIENTES_FETCHING: 'CLIENTES_FETCHING',
  ALMACENES_FETCHING: 'ALMACENES_FETCHING',
  ORDEN_VENTA_ADD: 'ORDEN_VENTA_ADD',
  MOVIMIENTO_REMOVE: 'MOVIMIENTO_REMOVE',
  ORDEN_VENTA_REMOVE: 'ORDEN_VENTA_REMOVE',
  MOVIMIENTO_ADD: 'MOVIMIENTO_ADD',
  MOVIMIENTOS_FETCHING: 'MOVIMIENTOS_FETCHING',
  ORDENES_VENTA_FETCHING: 'ORDENES_VENTA_FETCHING',
  MISMO_DESTINO: 'El destino debe ser diferente al origen',
  DATOS_FETCHING: 'DATOS_FETCHING',
  RESET_ALL_DATA: 'RESET_ALL_DATA',
  CONFIGURACION_FETCHING: 'CONFIGURACION_FETCHING',
  FETCHING_END: 'FETCHING_END',
  MOVIMIENTOS_UPDATE_CONSECUTIVO: 'MOVIMIENTOS_UPDATE_CONSECUTIVO',
  MOVIMIENTOS_UPDATE_CONSECUTIVO_OV: 'MOVIMIENTOS_UPDATE_CONSECUTIVO_OV',
  LOGIN_FAIL: 'Usuario o contraseña incorrecta',
  PASSWORD_FAIL: 'Usuario no existe',
  PASSWORD_OK: 'Se envió correo de recuperación',
  OBJETIVO_PDF_OK: 'Se envió el correo con éxito',
  DATOS_ACTUALIZADOS_OK: 'Se actualizaron los datos con éxito',
  FAIL: 'Ocurrió un error, inténtalo más tarde',
  EMAIL_ERROR: 'Email incorrecto',
  DATOS_ERROR: 'Favor de llenar todos los campos',
  EXPIRADO: 'TokenExpiredError',
};

export default types;
