/* eslint-disable no-console */
import { Button, Grid } from '@mui/material';
import AnyChart from 'anychart-react';
import React, { useEffect, useState } from 'react';
import anychart from 'anychart';
import './index.css';
import { getFechaHora } from '../Utils/validations';
const Graficas = ({
  datosCircular,
  datosBarra = [],
  title = ['Clientes (Cantidad OVs)', 'Productos (Cantidad)'],
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [chartWidth, setChartWidth] = useState(350);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    setChartWidth(windowWidth - 50);
  }, [windowWidth]);

  let stage = anychart.graphics.create();
  let chart1 = anychart.pie(datosCircular);
  chart1.bounds(0, 0, '100%', '50%');
  chart1.title(title[0]);
  let chart2 = anychart.column(datosBarra);
  chart2.bounds(0, '50%', '100%', '50%');
  chart2.title(title[1]);
  const divStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    width: windowWidth,
  };
  function saveAsPdf() {
    const { date, time } = getFechaHora();
    const filename = `Gráficas_${date}-${time}`;
    stage.saveAsPdf(windowWidth, true, 100, 50, filename);
  }
  function saveAsPng() {
    const { date, time } = getFechaHora();
    const filename = `Gráficas_${date}-${time}`;
    stage.saveAsPng(windowWidth, 500, 0.5, filename);
  }

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid item xs md={6}>
        <AnyChart
          instance={stage}
          width={chartWidth}
          height={800}
          charts={[chart1]}
        />
      </Grid>
      <Grid item xs md={6}>
        <AnyChart
          instance={stage}
          width={chartWidth}
          height={800}
          charts={[chart2]}
        />
      </Grid>
      <div style={divStyle}>
        <Button
          variant='contained'
          color='success'
          style={{ margin: 10 }}
          onClick={() => saveAsPdf()}
        >
          PDF
        </Button>
        <Button
          variant='contained'
          color='success'
          style={{ margin: 10 }}
          onClick={() => saveAsPng()}
        >
          PNG
        </Button>
      </div>
    </Grid>
  );
};

export default Graficas;
