/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFile,
  getUsuarios,
  setUsuarioNuevo,
  updateUsuario,
} from '../../api';
import { StyledPaper } from '../Paper/Paper';
import TablaProductos from '../TablaDeProductos/Tabla';
import {
  getFechaHora,
  validateEmail,
  validatePassword,
} from '../Utils/validations';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nombre',
  },
  {
    id: 'correo',
    numeric: false,
    disablePadding: false,
    label: 'Correo',
  },
  {
    id: 'rol',
    numeric: true,
    disablePadding: false,
    label: 'Rol',
  },
  {
    id: 'folio',
    numeric: true,
    disablePadding: false,
    label: 'Folio',
  },
  {
    id: 'coordinador',
    numeric: true,
    disablePadding: false,
    label: 'Coordinador',
  },
  {
    id: 'sustituto',
    numeric: true,
    disablePadding: false,
    label: 'Sustituto de',
  },
  {
    id: 'acciones',
    numeric: false,
    label: 'Acciones',
  },
];

export default function Usuarios() {
  const {
    inventarios: {
      usuario: { token },
      representantes
    },
  } = useSelector(state => {
    return state;
  });
  let variant = 'error';
  const listaEstatus = [
    { label: 'Activo', id: 1 },
    { label: 'Desactivado', id: 2 },
  ];
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [listaDeClientes, setListaDeClientes] = useState([]);
  const [listaDeRoles, setListaDeRoles] = useState([]);
  const [editar, setEditar] = useState(false);
  const [editando, setEditando] = useState(false);
  const [usuarioNombre, setUsuarioNombre] = useState('');
  const [usuarioPaterno, setUsuarioPaterno] = useState('');
  const [usuarioMaterno, setUsuarioMaterno] = useState('');
  const [usuarioFolio, setUsuarioFolio] = useState('');
  const [usuarioEstatus, setUsuarioEstatus] = useState(null);
  const [usuarioPassword, setUsuarioPassword] = useState('');
  const [usuarioCorreo, setUsuarioCorreo] = useState('');
  const [usuarioTel, setUsuarioTel] = useState('');
  const [usuarioRol, setUsuarioRol] = useState(null);
  const [searched, setSearched] = useState('');
  const [open, setOpen] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [coordinadorRepresentante, setCoordinadorRepresentante] = useState([]);
  const [coordinadores, setCoordinadores] = useState([]);

  const divStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  };

  const sendSnackA = message => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500 });
  };

  async function getAllUsuarios() {
    dispatch({ type: 'FETCHING_START' });
    try {
      const { tUsuarios, tRoles } = await getUsuarios(token);
      setFormato(tUsuarios);
      setListaDeRoles(tRoles);
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesión finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    } finally {
      dispatch({ type: 'FETCHING_END' });
    }
  }

  function setFormato(listaUsuarios) {
    let tmp = [];
    let tmpCoordinadores = [];
    listaUsuarios.forEach(element => {
      const { folios, coordinadores, NombreRol } = element;
      if (NombreRol === 'Coordinador') {
        tmpCoordinadores.push(element);
      }
      let listaFolios = '';
      if (folios.length > 1) {
        folios.forEach((element, i) => {
          if (element.Estatus)
            listaFolios =
              listaFolios + (listaFolios === '' ? ' ' : ', ') + element.label;
        });
      } else if (folios.length === 1) {
        listaFolios = folios[0].label;
      }
      let listaCoordinadores = '';
      if (coordinadores.length > 1) {
        coordinadores.forEach(element => {
          listaCoordinadores =
            listaCoordinadores +
            (listaCoordinadores === '' ? ' ' : ', ') +
            element.label;
        });
      } else if (coordinadores.length === 1) {
        listaCoordinadores = coordinadores[0].label;
      }
      const formateo = {
        id: element.IidTrabajador,
        nombre: `${element.Nombre} ${element.Paterno || ''} ${element.Materno || ''}`,
        correo: element.Email,
        rol: element.NombreRol,
        folio: listaFolios,
        coordinadores: listaCoordinadores,
        sustituto: element.NombreCompletoSustituto,
        acciones: 1,
        extra: element,
      };
      tmp.push(formateo);
    });
    setCoordinadores(tmpCoordinadores);
    setRows(tmp);
    setListaDeClientes(tmp);
  }

  useEffect(() => {
    getAllUsuarios();
  }, []);

  async function editItem(id) {
    const productoEdit = rows.filter(item => item.id === id)[0];
    setNewPassword(false);
    setEditando(productoEdit);
    setEditar(true);
    setOpen(true);
  }

  const exportar = async () => {
    const crearXLSX = [];
    let num = 0;
    const { date, time } = getFechaHora();
    const filename = `Usuarios_${date}-${time}`;
    dispatch({ type: 'FETCHING_START' });
    rows.forEach(element => {
      const { id, nombre, rol, correo, coordinadores, extra, folio, sustituto } = element;
      const { Estatus } = extra;
      const row = {
        num: num + 1,
        id,
        nombre,
        correo,
        folios: folio,
        rol,
        coordinadores,
        sustituto,
        estatus: Estatus,
      };
      num++;
      crearXLSX.push(row);
    });
    getFile(crearXLSX, filename);
    dispatch({ type: 'FETCHING_END' });
  };

  useEffect(() => {
    if (editando) {
      const {
        extra: { Nombre, Paterno, Materno, Estatus, Tel1, IidTrabajadorSustituido },
        extra,
      } = editando;
      const tmpRol =
        listaDeRoles.filter(item => item.label === editando.rol)[0] || [];
      const tmpEstatus =
        listaEstatus.filter(item => item.label === Estatus)[0] || [];
      let tmpCoordi = [];
      if (tmpRol.Descripcion === 'Sustituto') {
        tmpCoordi = representantes.filter(item => item.IidTrabajador === IidTrabajadorSustituido)[0]
        console.log(tmpCoordi)
      } else {
        extra.coordinadores.forEach(element => {
          const flag =
            coordinadores.filter(item => item.label === element.label)[0] ||
            false;
          if (flag) tmpCoordi.push(flag);
        });
      }

      console.log(tmpRol)
      setUsuarioCorreo(editando.correo || '');
      setUsuarioNombre(Nombre || '');
      setUsuarioPaterno(Paterno || '');
      setUsuarioMaterno(Materno || '');
      setUsuarioFolio(editando.folio);
      setUsuarioRol(tmpRol);
      setUsuarioTel(Tel1 || '');
      setUsuarioEstatus(tmpEstatus);
      setCoordinadorRepresentante(tmpCoordi || []);
    }
  }, [editando]);

  const guardar = async nuevo => {
    const payload = {
      coordinadores:
        usuarioRol.label === 'Representante' ? coordinadorRepresentante : [],
      sustituto: usuarioRol?.label === 'Sustituto' ? coordinadorRepresentante : [],
      usuarioPassword,
      usuarioPaterno,
      usuarioMaterno,
      usuarioEstatus,
      usuarioCorreo,
      usuarioNombre,
      usuarioFolio,
      newPassword,
      usuarioTel,
      usuarioRol,
      editando,
    };
    dispatch({ type: 'FETCHING_START' });
    try {
      dispatch({ type: 'FETCHING_START' });

      const { tUsuarios } = nuevo
        ? await setUsuarioNuevo(token, payload)
        : await updateUsuario(token, payload);
      if (tUsuarios) {
        variant = 'success';
        sendSnackA('Se guardó el usuario con éxito');
        limpiar();
        setFormato(tUsuarios);
        dispatch({
          type: 'REPRESENTANTES_FETCHING',
          payload: { representantes: tUsuarios },
        });
        dispatch({ type: 'FETCHING_END' });
      }
    } catch (error) {
      variant = 'error';
      if (error.toString().includes('TokenExpiredError')) {
        sendSnackA('Sesion finalizada');
        dispatch({ type: 'RESET_ALL_DATA' });
      } else {
        sendSnackA('Ocurrió un error, intenta mas tarde');
        dispatch({ type: 'FETCHING_END' });
      }
    }
  };

  const validarDatos = () => {
    if (newPassword) {
      if (
        usuarioNombre !== '' &&
        usuarioPassword !== '' &&
        usuarioPaterno !== '' &&
        !errorEmail &&
        usuarioEstatus !== null &&
        usuarioFolio !== '' &&
        usuarioRol !== null &&
        !errorPassword
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (usuarioRol && usuarioRol.label === 'Representante') {
        if (
          usuarioNombre !== '' &&
          usuarioPaterno !== '' &&
          !errorEmail &&
          usuarioEstatus !== null &&
          usuarioFolio !== '' &&
          usuarioRol !== null &&
          !errorPassword
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (
          usuarioNombre !== '' &&
          usuarioPaterno !== '' &&
          !errorEmail &&
          usuarioEstatus !== null &&
          usuarioRol !== null &&
          !errorPassword
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  function limpiar() {
    setEditando(null);
    setEditar(false);
    setUsuarioNombre('');
    setUsuarioPaterno('');
    setUsuarioMaterno('');
    setUsuarioFolio('');
    setUsuarioEstatus(null);
    setUsuarioPassword('');
    setUsuarioCorreo('');
    setUsuarioTel('');
    setUsuarioRol('');
    setOpen(false);
    setNewPassword(false);
    setCoordinadorRepresentante([])
  }

  useEffect(() => {
    if (open && !editando) {
      const tmpRol = listaDeRoles.filter(element =>
        element.label.includes('Representante')
      );
      setUsuarioRol(tmpRol[0] || null);
      const tmpEstatus = listaEstatus.filter(element =>
        element.label.includes('Activo')
      );
      setUsuarioEstatus(tmpEstatus[0] || null);
    }
  }, [open]);

  const cancelSearch = () => {
    if (searched !== '') {
      setSearched('');
      setRows(listaDeClientes);
    }
  };

  const handleChange = event => {
    const filteredRows = listaDeClientes.filter(row => {
      return (
        row?.nombre?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.folio?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row?.correo?.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    setRows(filteredRows);
    setSearched(event.target.value);
  };

  const handleClose = () => {
    limpiar();
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={divStyle}>
          <Paper
            component='form'
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: 500,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder='Búsqueda por nombre, clave y representante'
              inputProps={{ 'aria-label': 'Búsqueda por nombre y clave' }}
              value={searched}
              onChange={handleChange}
            />
            <IconButton
              type='button'
              sx={{ p: '10px' }}
              aria-label='search'
              onClick={() => cancelSearch()}
            >
              {searched !== '' ? (
                <CloseIcon color='error' />
              ) : (
                <SearchIcon color='primary' />
              )}
            </IconButton>
          </Paper>
        </div>
      </div>
      <div style={divStyle}>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab color='primary' size='medium' aria-label='add' sx={{ mr: 1 }}>
            <AddIcon onClick={() => setOpen(true)} />
          </Fab>
        </Box>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab color='warning' size='medium' aria-label='export' sx={{ mr: 1 }}>
            <FileDownloadIcon onClick={() => exportar()} />
          </Fab>
        </Box>
      </div>
      <div style={divStyle}>
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <StyledPaper>
            <Grid container wrap='nowrap' spacing={2}>
              <Grid item xs={12}>
                <TablaProductos
                  headers={headCells}
                  productos={rows}
                  detalles={false}
                  editItem={editItem}
                  mensaje={'Usuarios '}
                />
              </Grid>
            </Grid>
          </StyledPaper>
        </Box>
      </div>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        onClose={handleClose}
        maxWidth={'xl'}
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {editar ? 'Actualizar ' : 'Agregar '} Usuario
        </DialogTitle>
        <DialogContent dividers={'paper'}>
          <div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <TextField
                  id='outlined-nombre'
                  label='Nombre'
                  type='Text'
                  required
                  disabled={newPassword}
                  value={usuarioNombre}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={event => {
                    const newValue = event.target.value;
                    setUsuarioNombre(newValue);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <TextField
                  id='outlined-claveCli'
                  label='Apellido paterno'
                  type='Text'
                  required
                  disabled={newPassword}
                  value={usuarioPaterno}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={event => {
                    const newValue = event.target.value;
                    setUsuarioPaterno(newValue);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <TextField
                  id='outlined-claveCli'
                  label='Apellido materno'
                  type='Text'
                  disabled={newPassword}
                  value={usuarioMaterno}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={event => {
                    const newValue = event.target.value;
                    setUsuarioMaterno(newValue);
                  }}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <TextField
                  id='outlined-correo'
                  label='Correo'
                  required
                  type='Text'
                  disabled={newPassword}
                  value={usuarioCorreo}
                  error={errorEmail}
                  helperText={errorEmail ? 'Correo no valido' : ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={event => {
                    const newValue = event.target.value;
                    setErrorEmail(!validateEmail(newValue));
                    setUsuarioCorreo(newValue);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <TextField
                  id='outlined-correoPass'
                  label='Contraseña'
                  required
                  error={errorPassword}
                  helperText={errorPassword ? 'Contraseña Débil' : ''}
                  type='Text'
                  disabled={!newPassword && editar}
                  value={usuarioPassword}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={event => {
                    const newValue = event.target.value;
                    setErrorPassword(validatePassword(newValue) === 'Débil');
                    setUsuarioPassword(newValue);
                  }}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <TextField
                  id='outlined-claveCli'
                  label='Teléfono'
                  type='Text'
                  disabled={newPassword}
                  value={usuarioTel}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={event => {
                    const newValue = event.target.value;
                    setUsuarioTel(newValue);
                  }}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              {usuarioRol?.label === 'Sustituto' ?
                <Stack spacing={1} sx={{ width: '30%' }}>
                  <Autocomplete
                    options={representantes}
                    value={coordinadorRepresentante || []}
                    id='auto-producto'
                    clearOnEscape
                    onChange={(event, newValue) => {
                      setCoordinadorRepresentante(newValue || []);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant='standard'
                        label='Representantes'
                        placeholder='Representante'
                      />
                    )}
                  />
                </Stack>
                : (
                  <Stack spacing={1} sx={{ width: '30%' }}>
                    <TextField
                      id='outlined-claveCli'
                      label='Folio'
                      type='Text'
                      disabled={newPassword}
                      value={usuarioFolio}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={event => {
                        const newValue = event.target.value;
                        setUsuarioFolio(newValue);
                      }}
                      style={{ marginTop: 10 }}
                    />
                  </Stack>
                )}
              <Stack spacing={1} sx={{ width: '30%' }}>
                <Autocomplete
                  size='medium'
                  options={listaDeRoles}
                  disabled={newPassword}
                  value={usuarioRol}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  id='auto-rol'
                  clearOnEscape
                  onChange={(event, newValue) => {
                    setUsuarioRol(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      style={{ marginTop: -5 }}
                      {...params}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='Rol de usuario'
                      variant='standard'
                      helperText='Selecciona el rol'
                    />
                  )}
                />
              </Stack>
              <Stack spacing={1} sx={{ width: '30%' }}>
                <Autocomplete
                  size='medium'
                  options={listaEstatus}
                  disabled={newPassword}
                  value={usuarioEstatus}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  id='auto-rol'
                  clearOnEscape
                  onChange={(event, newValue) => {
                    setUsuarioEstatus(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      style={{ marginTop: -5 }}
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      label='Estatus'
                      variant='standard'
                      helperText='Estatus'
                    />
                  )}
                />
              </Stack>
            </div>
            <div style={divStyle}>
              {usuarioRol?.label === 'Representante' ? (
                <Stack spacing={3} sx={{ width: 500 }}>
                  <Autocomplete
                    multiple
                    options={coordinadores}
                    value={coordinadorRepresentante || []}
                    id='auto-producto'
                    clearOnEscape
                    onChange={(event, newValue) => {
                      setCoordinadorRepresentante(newValue || []);
                    }}
                    filterSelectedOptions
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant='standard'
                        label='Coordinadores'
                        placeholder='Coordinador'
                      />
                    )}
                  />
                </Stack>
              ) : null}
            </div>
            <div style={divStyle}>
              {editar ? (
                <>
                  <Button
                    variant='contained'
                    color='error'
                    onClick={() => {
                      limpiar();
                    }}
                  >
                    Cancelar
                  </Button>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={newPassword}
                          onChange={event => {
                            const newValue = event.target.checked;
                            setNewPassword(newValue);
                          }}
                        />
                      }
                      label='Nueva contraseña'
                    />
                  </FormGroup>
                  <Button
                    variant='contained'
                    disabled={validarDatos()}
                    onClick={() => guardar(false)}
                  >
                    Actualizar
                  </Button>
                </>
              ) : (
                <Button
                  variant='contained'
                  disabled={validarDatos()}
                  onClick={() => guardar(true)}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
