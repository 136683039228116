/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import React, { Fragment, useEffect, useState } from 'react';
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.secondary,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function RowDetails(props) {
  const { valores } = props;
  return (
    <Fragment>
      {Object.entries(valores).map(([key, val]) => (
        <TableRow key={key + val}>
          {Object.entries(val).map(([key2, val2]) => (
            <TableCell
              component='th'
              scope='row'
              key={key2 + val2}
              align={'left'}
            >
              {val2}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Fragment>
  );
}

const TablaOVS = ({ headers, productos, detalles, seleccionado }) => {
  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const globalTheme = useTheme();
  useEffect(() => {
    seleccionado(rowSelection);
  }, [rowSelection]);

  return (
    <MaterialReactTable
      localization={MRT_Localization_ES}
      columns={headers}
      data={productos}
      enableMultiRowSelection={false} //use radio buttons instead of checkboxes
      enableRowSelection
      enableDensityToggle={false}
      enableHiding={false}
      getRowId={row => row.FolioOV} //give each row a more useful id
      muiTableBodyRowProps={({ row }) => ({
        //add onClick to row to select upon clicking anywhere in the row
        onClick: row.getToggleSelectedHandler(),
        sx: { cursor: 'pointer' },
      })}
      renderDetailPanel={({ row }) => (
        <Box
          sx={{
            display: 'grid',
            width: '80%',
          }}
        >
          <Box sx={{ margin: 1 }}>
            <Typography variant='h6' gutterBottom component='div'>
              Detalles
            </Typography>
            <Table size='small' aria-label='purchases'>
              <TableHead>
                <StyledTableRow>
                  {Object.entries(detalles).map(([key, val]) => (
                    <TableCell
                      component='th'
                      scope='row'
                      key={key + ' detalle' + val}
                      align={'left'}
                    >
                      {val.label}
                    </TableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <RowDetails valores={row.original.detalles.valores} />
              </TableBody>
            </Table>
          </Box>
        </Box>
      )}
      defaultColumn={{
        maxSize: 100,
        minSize: 50,
        size: 60, //default size is usually 180
      }}
      onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
      state={{ rowSelection }} //pass our managed row selection state to the table to use
      // enableRowActions
      // renderRowActions={(row, index) => (
      //   <Box>
      //     <IconButton onClick={() => console.info('Edit', row)}>
      //       <EditIcon />
      //     </IconButton>
      //     <IconButton onClick={() => console.info('Delete')}>
      //       <DeleteIcon />
      //     </IconButton>
      //   </Box>
      // )}
      // enablePinning
      initialState={{
        showGlobalFilter: true,
      }}
      positionGlobalFilter='right'
      muiSearchTextFieldProps={{
        placeholder: `Buscar`,
        sx: { minWidth: '300px' },
      }}
      enableGlobalFilterModes
      enableGlobalFilter
    />
  );
};

export default TablaOVS;
