/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { utils, writeFile } from 'xlsx';

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

const maxTimeOut = 60000

export const login = (payload, token) =>
  API.post('auth/login', payload, {
    headers: {
      Authorization: `${token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.message);
    });

export const validCode = (payload, token) =>
  API.post('auth/code', payload, {
    headers: {
      Authorization: `${token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.message);
    });

export const validToken = async access_token =>
  API.get('auth/validation', {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const getClientes = async access_token =>
  API.get('admin/clientes', {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const getUsuarios = async access_token =>
  API.get('admin/usuarios', {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const getProductos = async access_token =>
  API.get('admin/productos', {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const password = formData => API.post('auth/login/password', formData);

export const getDatos = async access_token =>
  API.get('configuraciones', {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setTraslado = async (access_token, payload) =>
  API.post('movimientos/am', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setEntrada = async (access_token, payload) =>
  API.post('movimientos/am/entrada', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setTrasladoManual = async (access_token, payload) =>
  API.post('movimientos/am/manual', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setTrasladoBajaProducto = async (access_token, payload) =>
  API.post('movimientos/am/baja', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setTrasladoPDF = async (access_token, payload, nombre) =>
  API.post('movimientos/am/pdf', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    responseType: 'blob',
    timeout: maxTimeOut
  })
    .then(result => {
      const url = URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${nombre}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const cancelarAM = async (access_token, payload) =>
  API.post('movimientos/am/cancelar', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const autorizarAM = async (access_token, payload) =>
  API.post('movimientos/am/autorizar', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setOV = async (access_token, payload) =>
  API.post('movimientos/ov', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setOVManual = async (access_token, payload) =>
  API.post('movimientos/ov/manual', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setCancelarOv = async (access_token, payload) =>
  API.post('movimientos/ov/cancelar', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setAutorizarCancelarOv = async (access_token, payload) =>
  API.post('movimientos/ov/cancelar/autorizar', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setRechazarCancelarOv = async (access_token, payload) =>
  API.post('movimientos/ov/cancelar/rechazar', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setOvPDF = async (access_token, payload, nombre) =>
  API.post('movimientos/ov/pdf', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    responseType: 'blob',
    timeout: maxTimeOut
  })
    .then(result => {
      const url = URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${nombre}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const getInventario = async (
  access_token,
  idRepresentante,
  global = false
) =>
  API.get('representante/inventario', {
    headers: {
      Authorization: `${access_token}`,
    },
    params: {
      idRepresentante,
      global,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const getInventarioAlmacen = async (access_token, IidAlmacen) =>
  API.get('representante/inventario/almacen', {
    headers: {
      Authorization: `${access_token}`,
    },
    params: {
      IidAlmacen,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const getMovimientosR = async (access_token, idRepresentante) =>
  API.get('representante/movimientos', {
    headers: {
      Authorization: `${access_token}`,
    },
    params: {
      idRepresentante,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });
export const getOVsAutorizacionR = async access_token =>
  API.get('representante/autorizaciones', {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setAlmacen = async (access_token, payload) =>
  API.post('admin/almacen/nuevo', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const updateAlmacen = async (access_token, payload) =>
  API.put('admin/almacen/edit', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setClienteNuevo = async (access_token, payload) =>
  API.post('admin/clientes/nuevo', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const updateCliente = async (access_token, payload) =>
  API.put('admin/clientes/edit', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setUsuarioNuevo = async (access_token, payload) =>
  API.post('admin/usuarios/nuevo', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const updateUsuario = async (access_token, payload) =>
  API.put('admin/usuarios/edit', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const setProducto = async (access_token, payload) =>
  API.post('admin/producto/nuevo', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const updateProducto = async (access_token, payload) =>
  API.put('admin/producto/edit', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const getRecuperarPdf = async (access_token, payload, nombre) =>
  API.post('movimientos/recuperar/pdf', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    responseType: 'blob',
    timeout: maxTimeOut
  })
    .then(result => {
      const url = URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${nombre}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const getFile = async (productos, nombre) => {
  const worksheet = utils.json_to_sheet(productos);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  writeFile(workbook, nombre + '.xlsx');
};

export const setZona = async (access_token, payload) =>
  API.post('admin/zonas/nuevo', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const updateZona = async (access_token, payload) =>
  API.put('admin/zonas/edit', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const ultimoConsecutivo = async (access_token, payload) =>
  API.post('movimientos/consecutivo/', payload, {
    headers: {
      Authorization: `${access_token}`,
    },
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });

export const getCierreAlmacen = async (access_token, params) =>
  API.get('representante/inventario/almacen/cierre', {
    headers: {
      Authorization: `${access_token}`,
    },
    params,
    timeout: maxTimeOut
  })
    .then(result => {
      return result.data;
    })
    .catch(err => {
      throw new Error(err.response.data.err?.name);
    });
