/* eslint-disable no-unused-vars */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function Header() {
  const {
    inventarios: { usuario },
  } = useSelector(state => {
    return state;
  });
  const fecha = new Date().toLocaleDateString();
  const [nombreVendedor, setNombreVendedor] = useState('Representante');
  const [zonaVendedor, setZonaVendedor] = useState('Centro');
  const [rolUsuario, setRolUsuario] = useState('Representante');

  useEffect(() => {
    const { Nombre, Paterno, Materno, Zona, NombreRol } = usuario;
    const nombre = `${Nombre} ${Paterno ? Paterno : ''} ${
      Materno ? Materno : ''
    }`;
    setNombreVendedor(nombre);
    setZonaVendedor(Zona);
    setRolUsuario(NombreRol);
  }, [usuario]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box
            component='img'
            sx={{
              height: 50,
              width: 50,
              maxHeight: { xs: 50, md: 50 },
              maxWidth: { xs: 50, md: 50 },
            }}
            style={{
              marginLeft: 10,
              marginTop: 10,
            }}
            alt='Logo'
            src='/valent2.png'
          />
        </Grid>
        <Grid item xs={8}>
          <div></div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h5' gutterBottom>
              Valent de México
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
              {rolUsuario}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='subtitle1' gutterBottom>
              {nombreVendedor}
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
              {fecha}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
