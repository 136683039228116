import types from '../actions/types';

const initialState = {
  usuario: false,
  isFetching: false,
  clientes: false,
  movimientos: false,
  inventarios: false,
  almacenes: false,
  ordenesVenta: false,
  configuraciones: false,
  representantes: false,
  representante: false,
  token: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.USUARIO_LOGIN: {
      const { usuario } = action.payload;
      return {
        ...state,
        usuario,
      };
    }
    case types.SAVE_TOKEN: {
      const { token } = action.payload;
      return {
        ...state,
        token,
      };
    }
    case types.CLIENTES_FETCHING: {
      const { clientes } = action.payload;
      return {
        ...state,
        clientes,
      };
    }
    case types.ALMACENES_FETCHING: {
      const { almacenes } = action.payload;
      return {
        ...state,
        almacenes,
      };
    }
    case types.MOVIMIENTOS_FETCHING: {
      const { movimientos } = action.payload;
      return {
        ...state,
        movimientos,
      };
    }
    case types.MOVIMIENTOS_UPDATE_CONSECUTIVO: {
      const { ConsAM } = action.payload;
      return {
        ...state,
        usuario: {
          ...state.usuario,
          ConsAM,
        },
      };
    }
    case types.MOVIMIENTOS_UPDATE_CONSECUTIVO_OV: {
      const { ConsOV } = action.payload;
      return {
        ...state,
        usuario: {
          ...state.usuario,
          ConsOV,
        },
      };
    }
    case types.ORDENES_VENTA_FETCHING: {
      const { ordenesVenta } = action.payload;
      return {
        ...state,
        ordenesVenta,
      };
    }
    case types.ORDEN_VENTA_ADD: {
      const { ordeneVenta } = action.payload;
      return {
        ...state,
        ordenesVenta: [ordeneVenta, ...state.ordenesVenta],
      };
    }
    case types.MOVIMIENTO_ADD: {
      const { movimiento } = action.payload;
      return {
        ...state,
        movimientos: [movimiento, ...state.movimientos],
      };
    }
    case types.MOVIMIENTO_REMOVE: {
      const { folio } = action.payload;
      return {
        ...state,
        movimientos: state.movimientos.filter(
          mov => mov.FolioTraslado !== folio
        ),
      };
    }
    case types.ORDEN_VENTA_REMOVE: {
      const { folio } = action.payload;
      return {
        ...state,
        ordenesVenta: state.ordenesVenta.filter(mov => mov.FolioOV !== folio),
      };
    }
    case types.INVENTARIO_FETCHING: {
      const { inventarios } = action.payload;
      return {
        ...state,
        inventarios,
      };
    }
    case types.FETCHING_START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case types.FETCHING_END: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case types.RESET_ALL_DATA: {
      return { ...initialState };
    }
    case types.CONFIGURACION_FETCHING: {
      const { configuraciones } = action.payload;
      return {
        ...state,
        configuraciones,
      };
    }
    case types.REPRESENTANTES_FETCHING: {
      const { representantes } = action.payload;
      return {
        ...state,
        representantes,
      };
    }
    case types.REPRESENTANTE_FETCHING: {
      const { representante } = action.payload;
      return {
        ...state,
        representante,
      };
    }
    default: {
      return state;
    }
  }
};
