import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Admin from './components/Admin/Admin';
import AppBarExample from './components/AppBar/AppBar';
import Home from './components/Home/Home';
import Login from './components/Login';
import NotaCredito from './components/NotaCredito/NotaCredito';
import Ov from './components/OV/Ov';
import Reportes from './components/Reportes/Reportes';
import Traspasos from './components/Traspasos/Traspasos';

const theme = createTheme({
  palette: {
    primary: {
      main: '#008457',
      secondary: '#D7FFF4',
    },
    secondary: {
      main: '#00e676',
    },
  },
});

const ProtectedRoute = ({ usuario, children }) => {
  const {
    type: { name },
  } = children;
  const { NombreRol } = usuario;
  if (!usuario) {
    return <Navigate to='/login' replace />;
  } else if (name === 'Admin' && NombreRol !== 'Admin') {
    return <Navigate to='/login' replace />;
  }
  return children;
};

function App() {
  const {
    inventarios: { isFetching, usuario },
  } = useSelector(state => {
    return state;
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isFetching);
  }, [isFetching]);

  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <Dialog
          keepMounted
          open={open}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            Espera un momento...
          </DialogTitle>
          <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
            <Stack sx={{ color: 'grey.500' }} spacing={2}>
              <CircularProgress color='success' />
            </Stack>
          </DialogContent>
        </Dialog>
        {usuario ? <AppBarExample /> : null}
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route
            path='/'
            element={
              <ProtectedRoute usuario={usuario}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path='/ov'
            element={
              <ProtectedRoute usuario={usuario}>
                <Ov />
              </ProtectedRoute>
            }
          />
          <Route
            path='/nc'
            element={
              <ProtectedRoute usuario={usuario}>
                <NotaCredito />
              </ProtectedRoute>
            }
          />
          <Route
            path='/traspasos'
            element={
              <ProtectedRoute usuario={usuario}>
                <Traspasos />
              </ProtectedRoute>
            }
          />
          <Route
            path='/reportes'
            element={
              <ProtectedRoute usuario={usuario}>
                <Reportes />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin'
            element={
              <ProtectedRoute usuario={usuario}>
                <Admin />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
