import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Dialogo({ abrir, cerrar, titulo, mensaje, aceptar }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={abrir}
        onClose={cerrar}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{titulo}</DialogTitle>
        <DialogContent>
          <DialogContentText>{mensaje}</DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: 10,
          }}
        >
          <Button onClick={cerrar} color='warning' variant='contained'>
            No
          </Button>
          <Button
            onClick={aceptar}
            autoFocus
            color='success'
            variant='contained'
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
