/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import CancelIcon from '@mui/icons-material/Cancel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.secondary,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell key={'icon'} align={'left'}>
          Detalles
        </StyledTableCell>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id || headCell.name}
            align={['name', 'id'].includes(headCell.id) ? 'left' : 'right'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function TablaProductos({
  headers,
  productos,
  editItem,
  deleteItem,
  detalles,
  mensaje,
  crearPdf,
  cancelarMov,
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function RowDetails(props) {
    const { valores } = props;
    return (
      <Fragment>
        {Object.entries(valores).map(([key, val]) => (
          <TableRow key={key + val}>
            {Object.entries(val).map(([key2, val2]) => (
              <TableCell
                component='th'
                scope='row'
                key={key2 + val2}
                align={'left'}
              >
                {val2}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Fragment>
    );
  }

  function Row(props) {
    const { row, labelId, detalles, index } = props;
    const [open, setOpen] = useState(false);
    const valores = detalles ? row.detalles.valores : false;

    return (
      <Fragment>
        <StyledTableRow
          sx={{ '& > *': { borderBottom: 'unset' } }}
          hover
          tabIndex={-1}
        >
          <TableCell>
            {detalles.length > 0 ? (
              <IconButton
                aria-label='expand row'
                size='small'
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : null}
          </TableCell>
          {Object.entries(row).map(([key, val]) =>
            key !== 'detalles' && key !== 'extra' ? (
              <TableCell
                component='th'
                id={labelId}
                scope='row'
                key={key + val}
                align={['name', 'id'].includes(key) ? 'left' : 'right'}
              >
                {key !== 'acciones' ? (
                  key === 'id' ? (
                    index + 1
                  ) : (
                    val
                  )
                ) : (
                  <div>
                    {val === 1 ? (
                      <IconButton size='small' onClick={() => editItem(row.id)}>
                        <EditIcon color='primary' />
                      </IconButton>
                    ) : val === 2 ? (
                      <IconButton size='small' onClick={() => crearPdf(row)}>
                        <PictureAsPdfIcon color='primary' />
                      </IconButton>
                    ) : val === 3 ? (
                      <>
                        <IconButton size='small' onClick={() => crearPdf(row)}>
                          <PictureAsPdfIcon color='primary' />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => cancelarMov(row)}
                        >
                          <CancelIcon color='error' />
                        </IconButton>
                      </>
                    ) : val === 4 ? (
                      <></>
                    ) : val === 5 ? (
                      <>
                        <IconButton
                          size='small'
                          onClick={() => editItem(row.id)}
                        >
                          <EditIcon color='tertiary' />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => deleteItem(row.id)}
                        >
                          <CheckBoxIcon color='primary' />
                        </IconButton>
                      </>
                    ) : val === 6 ? (
                      <>
                        <IconButton size='small' onClick={() => editItem(row)}>
                          <CheckBoxIcon color='primary' />
                        </IconButton>
                        <IconButton size='small' onClick={() => crearPdf(row)}>
                          <PictureAsPdfIcon color='primary' />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => cancelarMov(row)}
                        >
                          <CancelIcon color='error' />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          size='small'
                          onClick={() => editItem(row.id)}
                        >
                          <EditIcon color='primary' />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => deleteItem(row.id)}
                        >
                          <DeleteIcon color='error' />
                        </IconButton>
                      </>
                    )}
                  </div>
                )}
              </TableCell>
            ) : null
          )}
        </StyledTableRow>
        {row && row.detalles ? (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant='h6' gutterBottom component='div'>
                    Detalles
                  </Typography>
                  <Table size='small' aria-label='purchases'>
                    <TableHead>
                      <StyledTableRow>
                        {Object.entries(detalles).map(([key, val]) => (
                          <TableCell
                            component='th'
                            id={labelId}
                            scope='row'
                            key={key + ' detalle' + val}
                            align={'left'}
                          >
                            {val.label}
                          </TableCell>
                        ))}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      <RowDetails valores={valores} />
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ) : null}
      </Fragment>
    );
  }

  useEffect(() => {
    setPage(0);
  }, [productos.length]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='small'>
        <EnhancedTableHead
          headCells={headers}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={productos.length}
        />
        <TableBody>
          {stableSort(productos, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              if (row.name || row.id) {
                return (
                  <Row
                    row={row}
                    key={
                      mensaje + (row.name || row.id) + index + (row.Lote || '')
                    }
                    index={row.id || index}
                    labelId={labelId}
                    detalles={detalles}
                  />
                );
              } else {
                return null;
              }
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 75, 100]}
        component='div'
        count={productos.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
        labelRowsPerPage={mensaje + 'por pagina'}
      />
    </TableContainer>
  );
}
