/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Almacenes from '../Almacenes/Almacenes';
import Clientes from '../Clientes/Clientes';
import Productos from '../Productos/Productos';
import Usuarios from '../Usuarios/Usuarios';
import Zonas from '../Zonas/Zonas';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const divStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: 10,
};

const Admin = () => {
  const {
    inventarios: {
      almacenes,
      inventarios,
      clientes,
      movimientos,
      ordenesVenta,
      configuraciones: { tMovimientos },
      usuario: { NombreRol, IidTrabajador, token },
      representantes,
      representante,
    },
  } = useSelector(state => {
    return state;
  });
  let variant = 'error';
  const [value, setValue] = useState(0);

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Paper elevation={2}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChangeValue}
              aria-label='basic tabs example'
            >
              <Tab label='Usuarios' {...a11yProps(0)} />
              <Tab label='Zonas' {...a11yProps(1)} />
              <Tab label='Almacenes' {...a11yProps(2)} />
              <Tab label='Productos' {...a11yProps(3)} />
              <Tab label='Clientes' {...a11yProps(4)} />
            </Tabs>
          </Box>
          <div style={divStyle}>
            <Box sx={{ width: '100%' }}>
              <TabPanel value={value} index={0}>
                <Usuarios />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Zonas />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Almacenes />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Productos />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Clientes />
              </TabPanel>
            </Box>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default Admin;
